import * as React from "react"
import Address from "@src/components/atoms/address/index"
import Tel from "@src/components/atoms/tel/index"

/**
 * 会社の詳細情報
 */
const CompanyOutline = () => (
<div className="outline">
    <dl className="outline-contents">
        <dt className="outline-title">会社名</dt>
        <dd className="outline-text">株式会社 メイン</dd>
    </dl>

    <dl className="outline-contents">
        <dt className="outline-title">設立</dt>
        <dd className="outline-text">2021年2月1日</dd>
    </dl>

    <dl className="outline-contents">
        <dt className="outline-title">代表者</dt>
        <dd className="outline-text">代表取締役　秦名 哲平</dd>
    </dl>

    <dl className="outline-contents">
        <dt className="outline-title">事業内容</dt>
        <dd className="outline-text">創造事業(デザイン) <br /> 革命事業(システム) <br /> 運用事業(マーケティング)</dd>
    </dl>

    <dl className="outline-contents">
        <dt className="outline-title">所在地</dt>
        <dd className="outline-text">
            <Address />
        </dd>
    </dl>

    <dl className="outline-contents">
        <dt className="outline-title">電話番号</dt>
        <dd className="outline-text">
            <Tel />
        </dd>
    </dl>

    <dl className="outline-contents">
        <dt className="outline-title">メールアドレス</dt>
        <dd className="outline-text">
            <a href="mailto:info@main-inc.biz">info@main-inc.biz</a>
        </dd>
    </dl>
</div>
)

export default CompanyOutline