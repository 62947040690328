import * as React from "react";
import Layout from "@src/components/templates/layout";
import Seo from "@src/components/seo";
import CompanyInfo from "@src/components/pages/company-info";

const CompanyInfoPage = () => {
    return(
        <Layout>
            <Seo title="会社概要" />
            <CompanyInfo />
        </Layout>
    )
}

export default CompanyInfoPage