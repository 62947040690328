import * as React from "react";
import CompanyOutline from "@src/components/molecules/company-outline/index";
import ContactInfo from "@src/components/molecules/contact-info/index";
import PageHeader from "@src/components/molecules/page-header/index";

import { Up } from "@src/components/molecules/fadein/index";

import "./style.css"

const CompanyInfo = () => {
    return(
        <article className="company-info">
            <PageHeader title="会社概要" />
            <section className="page-section">
                <div className="l-contents-padding">
                    <Up>
                        <div className="outline-wrap">
                            <h3 className="page-section-title">会社情報</h3>
                            <CompanyOutline />
                        </div>
                    </Up>
                </div>
            </section>
            <ContactInfo />
        </article>
    )
}

export default CompanyInfo